export function isMobile() {
    var toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    return toMatch.some(function (toMatchItem) {
        return navigator.userAgent.match(toMatchItem);
    });
}
