var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import BackgroundVideo from '../../resources/media/background.mp4';
import BackgroundImage from '../../resources/img/background.jpg';
import CardImage from '../../resources/img/card.jpg';
import BackImage from '../../resources/img/back.png';
import ForwardImage from '../../resources/img/forward.png';
import Signatures from './signatures';
import marked from "marked";
import { isMobile } from "./browser";
var classNames = require('classnames');
var numberOfPartsToLoad = 3;
var backgroundRatio = 1.77;
var initialTimeout = 1000;
var flipTimeout = 1000;
var ChristmasCard = /** @class */ (function (_super) {
    __extends(ChristmasCard, _super);
    function ChristmasCard(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            flipped: false,
            flipEnabled: true,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            partsLoaded: 0
        };
        _this.markdownRenderer = new marked.Renderer();
        _this.flipCard = _this.flipCard.bind(_this);
        _this.onResize = _this.onResize.bind(_this);
        _this.imagesLoaded = _this.imagesLoaded.bind(_this);
        window.onresize = _this.onResize;
        return _this;
    }
    ChristmasCard.prototype.render = function () {
        var classes = classNames({
            'card': true,
            'flipped': this.state.flipped
        });
        var cardHeight = this.state.windowWidth * 0.6 / 2.0;
        var fontSize = this.state.windowWidth / 1920;
        var windowRatio = this.state.windowWidth / this.state.windowHeight;
        var backgroundStyle = windowRatio > backgroundRatio ?
            { width: "100%" } : { height: "100%" };
        var sceneStyle = {
            height: cardHeight + "px",
            marginTop: "-" + cardHeight / 2 + "px",
            fontSize: fontSize + "em"
        };
        var buttonsStyle = {
            marginTop: cardHeight / 2 + 20 + "px"
        };
        var giftMessage = this.props.card.giftMessage ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: marked(this.props.card.giftMessage, { renderer: this.markdownRenderer }) } })) : '';
        var video = isMobile() ? (React.createElement("div", null)) : (React.createElement("div", { className: 'background' },
            React.createElement("video", { className: "image", style: backgroundStyle, muted: true, loop: true, autoPlay: true, playsInline: true },
                React.createElement("source", { src: BackgroundVideo, type: "video/mp4" }))));
        return (React.createElement("div", { className: this.props.visible ? 'main visible' : 'main' },
            React.createElement("div", { className: 'background' },
                React.createElement("img", { src: BackgroundImage, alt: "Background", className: "image", style: backgroundStyle, onLoad: this.imagesLoaded })),
            video,
            React.createElement("div", { className: 'scene', style: sceneStyle },
                React.createElement("div", { className: 'buttons', style: buttonsStyle },
                    React.createElement("img", { src: BackImage, alt: 'close', className: 'button', style: this.getButtonStyle(true), onClick: this.flipCard }),
                    React.createElement("img", { src: ForwardImage, alt: 'open', className: 'button', style: this.getButtonStyle(false), onClick: this.flipCard })),
                React.createElement("div", { className: classes },
                    React.createElement("div", { className: 'back-card' },
                        React.createElement("div", { className: 'content' },
                            React.createElement("span", { className: 'blue header' }, this.props.card.greeting),
                            React.createElement("p", null, this.props.card.message),
                            giftMessage,
                            React.createElement("p", null, this.props.card.footer),
                            React.createElement(Signatures, { signatures: this.props.card.signatures, onLoad: this.imagesLoaded }))),
                    React.createElement("div", { className: 'front' },
                        React.createElement("img", { src: CardImage, alt: 'card', className: 'image', onClick: this.flipCard, onLoad: this.imagesLoaded })),
                    React.createElement("div", { className: 'back' })))));
    };
    ChristmasCard.prototype.getButtonStyle = function (forward) {
        var enabled = (this.state.flipped && forward) || (!this.state.flipped && !forward);
        return {
            cursor: enabled ? 'pointer' : 'none',
            opacity: enabled ? '1' : '0.5'
        };
    };
    ChristmasCard.prototype.flipCard = function () {
        var _this = this;
        if (this.state.flipEnabled) {
            this.setState({ flipped: !this.state.flipped, flipEnabled: false });
            setTimeout(function () { return _this.setState({ flipEnabled: true }); }, flipTimeout);
        }
    };
    ChristmasCard.prototype.onResize = function () {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };
    ChristmasCard.prototype.imagesLoaded = function () {
        var numberOfPartsLoaded = this.state.partsLoaded + 1;
        this.setState({ partsLoaded: numberOfPartsLoaded });
        this.setLoadedState(numberOfPartsLoaded);
    };
    ChristmasCard.prototype.setLoadedState = function (numberOfPartsLoaded) {
        if (numberOfPartsLoaded === numberOfPartsToLoad) {
            setTimeout(this.props.onLoad, initialTimeout);
        }
    };
    return ChristmasCard;
}(React.Component));
export default ChristmasCard;
