var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var updateSignatureIndexInterval = 3000;
var Signatures = /** @class */ (function (_super) {
    __extends(Signatures, _super);
    function Signatures(props) {
        var _this = _super.call(this, props) || this;
        _this.intervalHandle = null;
        _this.state = { visibleSignatureIndex: 0, loadedImages: 0 };
        _this.updateVisibleSignature = _this.updateVisibleSignature.bind(_this);
        _this.signatureLoaded = _this.signatureLoaded.bind(_this);
        _this.nextSignature = _this.nextSignature.bind(_this);
        _this.intervalHandle = setInterval(_this.updateVisibleSignature, updateSignatureIndexInterval);
        return _this;
    }
    Signatures.prototype.render = function () {
        var _this = this;
        var signaturePhotos = this.props.signatures.map(function (signature, index) { return (React.createElement("div", { key: "signature-" + index, className: _this.state.visibleSignatureIndex == index ? 'visible photo' : 'photo' },
            React.createElement("img", { src: "images/photos/" + signature.photo, alt: signature.name, onLoad: _this.signatureLoaded, onClick: _this.nextSignature }),
            React.createElement("div", null, signature.name))); });
        return (React.createElement("div", { className: "signatures" }, signaturePhotos));
    };
    Signatures.prototype.updateVisibleSignature = function () {
        this.setState({
            visibleSignatureIndex: (this.state.visibleSignatureIndex + 1) % this.props.signatures.length
        });
    };
    Signatures.prototype.signatureLoaded = function () {
        var numberOfLoadedImages = this.state.loadedImages + 1;
        this.setState({ loadedImages: numberOfLoadedImages });
        this.setLoadedState(numberOfLoadedImages);
    };
    Signatures.prototype.nextSignature = function () {
        clearInterval(this.intervalHandle);
        this.intervalHandle = setInterval(this.updateVisibleSignature, updateSignatureIndexInterval);
        this.updateVisibleSignature();
    };
    Signatures.prototype.setLoadedState = function (numberOfLoadedImages) {
        if (numberOfLoadedImages === this.props.signatures.length) {
            this.props.onLoad();
        }
    };
    return Signatures;
}(React.Component));
export default Signatures;
